export function md5(inputString: string): string {
    const hexCharacters = "0123456789abcdef";

    function toHexString(num: number): string {
        let hexString = "";
        for (let j = 0; j <= 3; j++) {
            hexString +=
                hexCharacters.charAt((num >> (j * 8 + 4)) & 0x0f) +
                hexCharacters.charAt((num >> (j * 8)) & 0x0f);
        }
        return hexString;
    }

    function addUnsigned(x: number, y: number): number {
        const low = (x & 0xffff) + (y & 0xffff);
        const high = (x >> 16) + (y >> 16) + (low >> 16);
        return (high << 16) | (low & 0xffff);
    }

    function rotateLeft(n: number, bits: number): number {
        return (n << bits) | (n >>> (32 - bits));
    }

    function combineFunctions(
        q: number,
        a: number,
        b: number,
        x: number,
        s: number,
        t: number,
    ): number {
        return addUnsigned(
            rotateLeft(addUnsigned(addUnsigned(a, q), addUnsigned(x, t)), s),
            b,
        );
    }

    function fFunction(
        a: number,
        b: number,
        c: number,
        d: number,
        x: number,
        s: number,
        t: number,
    ): number {
        return combineFunctions((b & c) | (~b & d), a, b, x, s, t);
    }

    function gFunction(
        a: number,
        b: number,
        c: number,
        d: number,
        x: number,
        s: number,
        t: number,
    ): number {
        return combineFunctions((b & d) | (c & ~d), a, b, x, s, t);
    }

    function hFunction(
        a: number,
        b: number,
        c: number,
        d: number,
        x: number,
        s: number,
        t: number,
    ): number {
        return combineFunctions(b ^ c ^ d, a, b, x, s, t);
    }

    function iFunction(
        a: number,
        b: number,
        c: number,
        d: number,
        x: number,
        s: number,
        t: number,
    ): number {
        return combineFunctions(c ^ (b | ~d), a, b, x, s, t);
    }

    function stringToBlocks(input: string): number[] {
        const blocksCount = ((input.length + 8) >> 6) + 1;
        const blocks = new Array<number>(blocksCount * 16).fill(0);
        for (let i = 0; i < input.length; i++) {
            blocks[i >> 2] |= input.charCodeAt(i) << ((i % 4) * 8);
        }
        blocks[input.length >> 2] |= 0x80 << ((input.length % 4) * 8);
        blocks[blocksCount * 16 - 2] = input.length * 8;
        return blocks;
    }

    const inputBlocks = stringToBlocks(inputString);
    let a = 1732584193,
        b = -271733879,
        c = -1732584194,
        d = 271733878;

    for (let i = 0; i < inputBlocks.length; i += 16) {
        const oldA = a,
            oldB = b,
            oldC = c,
            oldD = d;

        a = fFunction(a, b, c, d, inputBlocks[i + 0], 7, -680876936);
        d = fFunction(d, a, b, c, inputBlocks[i + 1], 12, -389564586);
        c = fFunction(c, d, a, b, inputBlocks[i + 2], 17, 606105819);
        b = fFunction(b, c, d, a, inputBlocks[i + 3], 22, -1044525330);
        a = fFunction(a, b, c, d, inputBlocks[i + 4], 7, -176418897);
        d = fFunction(d, a, b, c, inputBlocks[i + 5], 12, 1200080426);
        c = fFunction(c, d, a, b, inputBlocks[i + 6], 17, -1473231341);
        b = fFunction(b, c, d, a, inputBlocks[i + 7], 22, -45705983);
        a = fFunction(a, b, c, d, inputBlocks[i + 8], 7, 1770035416);
        d = fFunction(d, a, b, c, inputBlocks[i + 9], 12, -1958414417);
        c = fFunction(c, d, a, b, inputBlocks[i + 10], 17, -42063);
        b = fFunction(b, c, d, a, inputBlocks[i + 11], 22, -1990404162);
        a = fFunction(a, b, c, d, inputBlocks[i + 12], 7, 1804603682);
        d = fFunction(d, a, b, c, inputBlocks[i + 13], 12, -40341101);
        c = fFunction(c, d, a, b, inputBlocks[i + 14], 17, -1502002290);
        b = fFunction(b, c, d, a, inputBlocks[i + 15], 22, 1236535329);

        a = gFunction(a, b, c, d, inputBlocks[i + 1], 5, -165796510);
        d = gFunction(d, a, b, c, inputBlocks[i + 6], 9, -1069501632);
        c = gFunction(c, d, a, b, inputBlocks[i + 11], 14, 643717713);
        b = gFunction(b, c, d, a, inputBlocks[i + 0], 20, -373897302);
        a = gFunction(a, b, c, d, inputBlocks[i + 5], 5, -701558691);
        d = gFunction(d, a, b, c, inputBlocks[i + 10], 9, 38016083);
        c = gFunction(c, d, a, b, inputBlocks[i + 15], 14, -660478335);
        b = gFunction(b, c, d, a, inputBlocks[i + 4], 20, -405537848);
        a = gFunction(a, b, c, d, inputBlocks[i + 9], 5, 568446438);
        d = gFunction(d, a, b, c, inputBlocks[i + 14], 9, -1019803690);
        c = gFunction(c, d, a, b, inputBlocks[i + 3], 14, -187363961);
        b = gFunction(b, c, d, a, inputBlocks[i + 8], 20, 1163531501);
        a = gFunction(a, b, c, d, inputBlocks[i + 13], 5, -1444681467);
        d = gFunction(d, a, b, c, inputBlocks[i + 2], 9, -51403784);
        c = gFunction(c, d, a, b, inputBlocks[i + 7], 14, 1735328473);
        b = gFunction(b, c, d, a, inputBlocks[i + 12], 20, -1926607734);

        a = hFunction(a, b, c, d, inputBlocks[i + 5], 4, -378558);
        d = hFunction(d, a, b, c, inputBlocks[i + 8], 11, -2022574463);
        c = hFunction(c, d, a, b, inputBlocks[i + 11], 16, 1839030562);
        b = hFunction(b, c, d, a, inputBlocks[i + 14], 23, -35309556);
        a = hFunction(a, b, c, d, inputBlocks[i + 1], 4, -1530992060);
        d = hFunction(d, a, b, c, inputBlocks[i + 4], 11, 1272893353);
        c = hFunction(c, d, a, b, inputBlocks[i + 7], 16, -155497632);
        b = hFunction(b, c, d, a, inputBlocks[i + 10], 23, -1094730640);
        a = hFunction(a, b, c, d, inputBlocks[i + 13], 4, 681279174);
        d = hFunction(d, a, b, c, inputBlocks[i + 0], 11, -358537222);
        c = hFunction(c, d, a, b, inputBlocks[i + 3], 16, -722521979);
        b = hFunction(b, c, d, a, inputBlocks[i + 6], 23, 76029189);
        a = hFunction(a, b, c, d, inputBlocks[i + 9], 4, -640364487);
        d = hFunction(d, a, b, c, inputBlocks[i + 12], 11, -421815835);
        c = hFunction(c, d, a, b, inputBlocks[i + 15], 16, 530742520);
        b = hFunction(b, c, d, a, inputBlocks[i + 2], 23, -995338651);

        a = iFunction(a, b, c, d, inputBlocks[i + 0], 6, -198630844);
        d = iFunction(d, a, b, c, inputBlocks[i + 7], 10, 1126891415);
        c = iFunction(c, d, a, b, inputBlocks[i + 14], 15, -1416354905);
        b = iFunction(b, c, d, a, inputBlocks[i + 5], 21, -57434055);
        a = iFunction(a, b, c, d, inputBlocks[i + 12], 6, 1700485571);
        d = iFunction(d, a, b, c, inputBlocks[i + 3], 10, -1894986606);
        c = iFunction(c, d, a, b, inputBlocks[i + 10], 15, -1051523);
        b = iFunction(b, c, d, a, inputBlocks[i + 1], 21, -2054922799);
        a = iFunction(a, b, c, d, inputBlocks[i + 8], 6, 1873313359);
        d = iFunction(d, a, b, c, inputBlocks[i + 15], 10, -30611744);
        c = iFunction(c, d, a, b, inputBlocks[i + 6], 15, -1560198380);
        b = iFunction(b, c, d, a, inputBlocks[i + 13], 21, 1309151649);
        a = iFunction(a, b, c, d, inputBlocks[i + 4], 6, -145523070);
        d = iFunction(d, a, b, c, inputBlocks[i + 11], 10, -1120210379);
        c = iFunction(c, d, a, b, inputBlocks[i + 2], 15, 718787259);
        b = iFunction(b, c, d, a, inputBlocks[i + 9], 21, -343485551);

        a = addUnsigned(a, oldA);
        b = addUnsigned(b, oldB);
        c = addUnsigned(c, oldC);
        d = addUnsigned(d, oldD);
    }

    return toHexString(a) + toHexString(b) + toHexString(c) + toHexString(d);
}
