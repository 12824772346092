export const useSlugStore = defineStore("slug", () => {
    const slug = getSlug(useRoute().fullPath.toString().split("/"));

    function getSlug(pathArray: string[]): string | undefined {
        if (pathArray[1] === "") {
            return undefined;
        }
        return pathArray[1];
    }

    return {
        slug,
    };
});
