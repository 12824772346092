import { verify } from "@/lib/api/verify";

export const useUserStore = defineStore("userStore", () => {
    const tokenStore = useTokenStore();
    const { token } = storeToRefs(tokenStore);
    const { setToken } = tokenStore;
    const { slug } = useSlugStore();

    const query = useQuery({
        queryKey: ["smake-use.merchantApi.app.verify", slug],
        queryFn: verify,
        select: (data) => data.user,
        retry: false,
        enabled: computed(() => !!token.value),
    });

    const { error } = query;

    watchEffect(() => {
        if (!error.value) return;

        setToken();
    });

    return query;
});
