import type { UseStorageOptions } from "@vueuse/core";

export default <T>(
    name: string,
    defaultValue: T,
    options?: UseStorageOptions<T>,
) => {
    const { slug } = useSlugStore();

    return useLocalStorage(`${slug ?? "*"}.${name}`, defaultValue, options);
};
