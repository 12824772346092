import { default as _91id_93iSbKLx93nlMeta } from "/home/forge/merchants.smake.construction/releases/v1.33.2/pages/[slug]/catalog/logos/[id].vue?macro=true";
import { default as index0YJ6CQ4LFSMeta } from "/home/forge/merchants.smake.construction/releases/v1.33.2/pages/[slug]/catalog/logos/index.vue?macro=true";
import { default as _91id_93t16he2gJ85Meta } from "/home/forge/merchants.smake.construction/releases/v1.33.2/pages/[slug]/customers/[customer]/logos/[id].vue?macro=true";
import { default as indexNkByASp9vxMeta } from "/home/forge/merchants.smake.construction/releases/v1.33.2/pages/[slug]/customers/[customer]/logos/index.vue?macro=true";
import { default as colorsUlMDU2N0ByMeta } from "/home/forge/merchants.smake.construction/releases/v1.33.2/pages/[slug]/customers/[id]/colors.vue?macro=true";
import { default as favorite_45variantsy0G8tG7lu0Meta } from "/home/forge/merchants.smake.construction/releases/v1.33.2/pages/[slug]/customers/[id]/favorite-variants.vue?macro=true";
import { default as indexHDfkfY899CMeta } from "/home/forge/merchants.smake.construction/releases/v1.33.2/pages/[slug]/customers/[id]/index.vue?macro=true";
import { default as indexoOWPtKF17qMeta } from "/home/forge/merchants.smake.construction/releases/v1.33.2/pages/[slug]/customers/index.vue?macro=true";
import { default as dashboard80MydcHrVIMeta } from "/home/forge/merchants.smake.construction/releases/v1.33.2/pages/[slug]/dashboard.vue?macro=true";
import { default as entranceo4XoqIt2qmMeta } from "/home/forge/merchants.smake.construction/releases/v1.33.2/pages/[slug]/entrance.vue?macro=true";
import { default as indexYPI32fJfrJMeta } from "/home/forge/merchants.smake.construction/releases/v1.33.2/pages/[slug]/index.vue?macro=true";
import { default as loginuGAc5XVtvqMeta } from "/home/forge/merchants.smake.construction/releases/v1.33.2/pages/[slug]/login.vue?macro=true";
import { default as indexgF7LgzdoBQMeta } from "/home/forge/merchants.smake.construction/releases/v1.33.2/pages/index.vue?macro=true";
export default [
  {
    name: "slug-catalog-logos-id",
    path: "/:slug()/catalog/logos/:id()",
    meta: _91id_93iSbKLx93nlMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.33.2/pages/[slug]/catalog/logos/[id].vue")
  },
  {
    name: "slug-catalog-logos",
    path: "/:slug()/catalog/logos",
    meta: index0YJ6CQ4LFSMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.33.2/pages/[slug]/catalog/logos/index.vue")
  },
  {
    name: "slug-customers-customer-logos-id",
    path: "/:slug()/customers/:customer()/logos/:id()",
    meta: _91id_93t16he2gJ85Meta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.33.2/pages/[slug]/customers/[customer]/logos/[id].vue")
  },
  {
    name: "slug-customers-customer-logos",
    path: "/:slug()/customers/:customer()/logos",
    meta: indexNkByASp9vxMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.33.2/pages/[slug]/customers/[customer]/logos/index.vue")
  },
  {
    name: "slug-customers-id-colors",
    path: "/:slug()/customers/:id()/colors",
    meta: colorsUlMDU2N0ByMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.33.2/pages/[slug]/customers/[id]/colors.vue")
  },
  {
    name: "slug-customers-id-favorite-variants",
    path: "/:slug()/customers/:id()/favorite-variants",
    meta: favorite_45variantsy0G8tG7lu0Meta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.33.2/pages/[slug]/customers/[id]/favorite-variants.vue")
  },
  {
    name: "slug-customers-id",
    path: "/:slug()/customers/:id()",
    meta: indexHDfkfY899CMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.33.2/pages/[slug]/customers/[id]/index.vue")
  },
  {
    name: "slug-customers",
    path: "/:slug()/customers",
    meta: indexoOWPtKF17qMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.33.2/pages/[slug]/customers/index.vue")
  },
  {
    name: "slug-dashboard",
    path: "/:slug()/dashboard",
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.33.2/pages/[slug]/dashboard.vue")
  },
  {
    name: "slug-entrance",
    path: "/:slug()/entrance",
    meta: entranceo4XoqIt2qmMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.33.2/pages/[slug]/entrance.vue")
  },
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.33.2/pages/[slug]/index.vue")
  },
  {
    name: "slug-login",
    path: "/:slug()/login",
    meta: loginuGAc5XVtvqMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.33.2/pages/[slug]/login.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.33.2/pages/index.vue")
  }
]