/**
 * Authorize user
 */
export default defineNuxtRouteMiddleware(async (to) => {
    const { slug } = useSlugStore();

    if (!slug) {
        return showError({
            statusCode: 404,
        });
    }

    const { isAuthorised } = useAuthorisationChecker();
    const isAuthorisedForPage = isAuthorised(to.meta);

    if (!isAuthorisedForPage) {
        return showError({
            statusCode: 404,
        });
    }

    const tokenStore = useTokenStore();
    const { isLoggedIn } = storeToRefs(tokenStore);
    const { attemptNavigateToRedirect } = tokenStore;

    if (isLoggedIn.value && to.name === "slug-login") {
        return await attemptNavigateToRedirect();
    }
});
