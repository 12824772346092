import {
    faArrowLeft,
    faPersonToDoor,
    faCircleCheck,
    type IconDefinition,
} from "@fortawesome/pro-regular-svg-icons";

export const regularIcons: IconDefinition[] = [
    faArrowLeft,
    faPersonToDoor,
    faCircleCheck,
];
