import {
    type IconDefinition,
    faCaretDown,
    faCircle,
    faCheck,
    faText,
    faUser,
    faUserSecret,
    faShoppingBasket,
    faImages,
    faEllipsisVertical,
    faAngleUp,
    faLockKeyhole,
    faLockKeyholeOpen,
    faCircleStar,
    faCircleSmall,
    faCircleUser,
} from "@fortawesome/pro-solid-svg-icons";

export const solidIcons: IconDefinition[] = [
    faCaretDown,
    faCircle,
    faCheck,
    faText,
    faUser,
    faUserSecret,
    faShoppingBasket,
    faImages,
    faEllipsisVertical,
    faAngleUp,
    faLockKeyhole,
    faLockKeyholeOpen,
    faCircleStar,
    faCircleSmall,
    faCircleUser,
];
