/**
 * Ensures user is authenticated
 */
export default defineNuxtRouteMiddleware((to) => {
    const { slug } = useSlugStore();

    if (!slug) {
        return showError({
            statusCode: 404,
        });
    }

    if (isGuestPage(to)) {
        return;
    }

    const { isLoggedIn } = storeToRefs(useTokenStore());

    if (to.name !== "slug-entrance" && !isLoggedIn.value) {
        return navigateTo({
            name: "slug-login",
            params: {
                slug: slug,
            },
            ...(!is404Page(to) && { query: { redirect: to.fullPath } }),
        });
    }
});
